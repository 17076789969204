export function parseId(id: string) {
  return id.split("-")[1];
}

export function isValidUrl(urlString) {
  try {
    const url = new URL(urlString);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (e) {
    return false;
  }
}

export function checkEmptyFields(fieldArr) {
  if (fieldArr.length === 0) {
    return true; // Array is empty
  }
  const empty = (element) => element == "";
  return fieldArr.some(empty);
}
