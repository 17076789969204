import AdInput from "../../shared/models/AdInput";
import { post, get, patch } from "./rest-service";

const apiUrl = process.env.REACT_APP_API_URL;

export async function adminCreateAd(token: string, ad: AdInput) {
  return await post(`${apiUrl}/admin/ad`, ad, token).then((res) => {
    return res;
  });
}

export function getAllLanguages(token: string) {
  return get(`${apiUrl}/admin/language/all`, token);
}

export function getAllAds(token: string) {
  return get(`${apiUrl}/admin/ad/all`, token);
}

export function updateAd(
  token: string,
  ad_id: number,
  url: string,
  language: string,
  active: boolean,
  hover_title: string,
  hover_pic: string,
  hover_text: string,
  suggestion_prefix: string,
  suggestion_text: string,
  inspo_prefix: string,
  inspo_text: string,
  utensil_prefix: string,
  utensil_text: string,
  hover_pic_portrait: boolean,
  country_ids: number[],
  cpm: number
) {
  return patch(
    `${apiUrl}/admin/ad?ad_id=${ad_id}`,
    {
      url,
      language,
      active,
      hover_title,
      hover_pic,
      hover_text,
      suggestion_prefix,
      suggestion_text,
      inspo_prefix,
      inspo_text,
      utensil_prefix,
      utensil_text,
      hover_pic_portrait,
      country_ids,
      cpm
    },
    token
  ).then((res) => {
    return res;
  });
}

export function updateBulkAds(
  token: string,
  hover_title: string,
  hover_text: string,
  hover_pic: string,
  hover_pic_portrait: boolean,
  url: string,
  language: string,
  active: boolean,
  suggestion_prefix: string,
  suggestion_text: string,
  inspo_prefix: string,
  inspo_text: string,
  utensil_prefix: string,
  utensil_text: string,
  ad_ids: number[],
  cpm?: number,
  publisher_ids?: [],
  country_ids?: [],
) {
  return patch(
    `${apiUrl}/admin/ad/bulk`,
    {
      ad_ids,
      url,
      language,
      active,
      hover_title,
      hover_pic,
      hover_text,
      hover_pic_portrait,
      suggestion_prefix,
      suggestion_text,
      inspo_prefix,
      inspo_text,
      utensil_prefix,
      utensil_text,
      country_ids,
      publisher_ids,
      cpm
    },
    token
  ).then((res) => {
    return res;
  });
}

export function updateBulkAdsState(
  token: string,
  ad_ids: number[],
  active: boolean
) {
  return patch(
    `${apiUrl}/admin/ad/bulk/active`,
    { ad_ids, active },
    token
  ).then((res) => {
    return res;
  });
}

export function getCountrySearch(token: string, name: string) {
  return get(`${apiUrl}/admin/country/search?name=${name}`, token).then(
    (res) => {
      return res;
    }
  );
}
