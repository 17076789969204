import IconDict from "../../../../services/icon-service";
import Icon from "../../atoms/Icon";
import style from "./HoverInfo.module.scss";

function HoverInfo(props: HoverInfoProps) {
  if (props.text === "") {
    console.warn("Hover info without text");
  }
  return (
    <div className={style.hover_wrapper}>
      <div className={style.content}>
        <span className="material-symbols-outlined">info</span>
      </div>
      <p className={style.hover_text}>{props.text}</p>
    </div>
  );
}

export default HoverInfo;

interface HoverInfoProps {
  text?: string;
}
