import style from "./TextAreaInput.module.scss";
import { InputProps } from "../../../models/InputProps";
import { FormInputProps } from "../../../models/FormInputProps";
import { useEffect, useRef } from "react";

export default function TextAreaInput(props: TextAreaInputProps) {
  const textarea = useRef(null);

  const redStar = <span className={style.redStar}>*</span>;

  return (
    <div className={style.text_area_input}>
      <p className={style.title}>
        {`${props.title}`} {props.mandatory && redStar}
      </p>
      <textarea
        ref={textarea}
        onChange={(e) =>
          props.setValue
            ? props.setValue(props.name, e.target.value)
            : props.handleChange(e)
        }
        className={style.input}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.value}
        required={props.mandatory}
        maxLength={props.maxLength}
      ></textarea>
    </div>
  );
}

export interface TextAreaInputProps extends InputProps, FormInputProps {
  maxLength: number;
}
