import { FormInputProps } from "../../../models/FormInputProps";
import { InputProps } from "../../../models/InputProps";
import HoverInfo from "../../molecules/hover-info/HoverInfo";
import style from "./Dropdown.module.scss";

function Dropdown(props: DropdownProps) {
  const redStar = <span className={style.redStar}>*</span>;

  return (
    <div>
      <p className={style.title}>
        {`${props.title}`} {props.mandatory && redStar}
      </p>
      <div className={style.input_container}>
        <select
          name={props.name}
          onChange={(e) => {
            props.setValue(props.name, e.target.value);
            props.handleClick(e.target.value);
          }}
          id={props.id}
          defaultValue={props.value}
          className={style.input}
          required={props.mandatory}
        >
          <option value="" disabled selected hidden>
            {props.placeholder}
          </option>
          {props.options.map((item) => (
            <option value={item.keyCode} key={item.keyCode}>
              {item.displayValue}
            </option>
          ))}
        </select>
      </div>
      {props.hoverText && (
        <div className={style.hover_info}>
          <HoverInfo text={props.hoverText} />
        </div>
      )}
    </div>
  );
}

export default Dropdown;

export interface DropdownProps extends InputProps, FormInputProps {
  options?: any[];
}
