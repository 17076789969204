import style from "./TextInput.module.scss";
import { useRef } from "react";
import { TextInputType } from "../../molecules/form-input-information/FormInputInformation";
import { InputProps } from "../../../models/InputProps";

export default function TextInput(props: TextInputProps) {
  const input = useRef(null);

  return (
    <div className={style.input_container}>
      <label className={style.label} htmlFor={props.name}>
        {props.name}
      </label>
      <input
        ref={input}
        onChange={(e) =>
          props.setValue
            ? props.setValue(props.name, e.target.value)
            : props.handleChange(e)
        }
        className={style.input}
        type={props.type.valueOf()}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        required={props.mandatory}
        value={props.value}
        disabled={props.disabled}
      />
    </div>
  );
}

export interface TextInputProps extends InputProps {
  type: TextInputType;
}
