import { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { UserType } from "./models/user";
import NavBar from "./shared/components/templates/NavBar/NavBar";
import AdminRouting from "./routing/AdminRouting";

function App() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userType, setUserType] = useState<number>();

  useEffect(() => {
    async function getToken() {
      setUserType(user.type);
      if (Object.values(UserType).includes(user.type)) {
        const token = await getAccessTokenSilently({ ignoreCache: true });
      }
    }
    getToken();
  }, [setUserType]);

  return (
    <>
      <BrowserRouter>
        <NavBar userType={userType} />
        <div className="content">
          <Routes>
            {userType === UserType.Admin && (
              <>
                <Route path="*" element={<Navigate to="/admin/ads" />} />
                <Route path="/admin/*" element={<AdminRouting />} />
              </>
            )}
            {userType === undefined && (
              //TODO: add nice loading gif
              <>
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/" element={<h2>Loading...</h2>} />
              </>
            )}
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default withAuthenticationRequired(App);
