export async function get(url: string, token?: string) {
  try {
    return await fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-cache'
      },
    }).then((res) => res.json());
  } catch (error: any) {
    return(error.message);
  }
}

export async function post(url: string, body: object, token?: string) {
  try {
    return await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    })
    .then((res) => res.json());
  } catch (error: any) {
    return(error.message);
  }
}

export async function patch(url: string, body: object, token?: string) {
  try {
    return await fetch(url, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        // "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());
  } catch (error: any) {
    return(error.message);
  }
}

export async function put(url: string, body: object, token?: string) {
  try {
    return await fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        // "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());
  } catch (error: any) {
    return(error.message);
  }
}