import { Route, Routes } from "react-router-dom";
import AdminAdOverview from "../pages/ad-overview/AdminAdOverview";
import NotFound from "../pages/not-found/NotFound";
import AdminPublisherOverview from "../pages/publisher-overview/AdminPublisherOverview";

const AdminRouting = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="ads" element={<AdminAdOverview />} />
      <Route path="publishers" element={<AdminPublisherOverview />} />
    </Routes>
  );
};

export default AdminRouting;
