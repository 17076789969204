export enum UserType {
  Advertiser = 0,
  Publisher = 1,
  Admin = 2
}

export interface Advertiser {
  id: number
  first_name: string
  last_name: string
  onboarded: boolean
}

export interface Admin {
  id: number
  first_name: string
  last_name: string
}