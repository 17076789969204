import style from './SaveAndContinueButton.module.scss'

export default function SaveAndContinueButton(props:SaveAndContinueButtonProps) {
    
    return (
        <div className={style.my_button} onClick={props.handleClick}>
            Save and continue
        </div>
    )
}

interface SaveAndContinueButtonProps {
    handleClick? : any
    name: string
}

