import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import FormInputInformation from "../../molecules/form-input-information/FormInputInformation";
import style from "./CreatePublisherForm.module.scss";
import SaveAndContinueButton from "../../atoms/SaveAndContinueButton/SaveAndContinueButton";
import { getAllLanguages } from "../../../../services/api-service/ad-service";
import { useAuth0 } from "@auth0/auth0-react";
import {
  checkEmptyFields,
  isValidUrl,
} from "../../../../services/util-service";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import { Toast } from "react-bootstrap";
import { createPublisher } from "../../../../services/api-service/publisher-service";

type Props = {
  setShowCreatePublisher: (showCreatePublisher: boolean) => void;
  showCreatePublisher: boolean;
  onClose: () => void;
};

const CreatePublisherForm = ({
  showCreatePublisher,
  setShowCreatePublisher,
  onClose,
}: Props) => {
  const formElem = useRef(null);
  const [languages, setLanguages] = useState([
    {
      keyCode: "",
      displayValue: "",
    },
  ]);
  const [publisher, setPublisher] = useState({
    name: "",
    languages: "",
    url: "",
    excluded_brands: "",
    excluded_pages: "",
    excluded_products: "",
    ad_type: "HOVERBOX",
    allow_alts: true,
    allow_inspo: true,
    allow_gl: false,
    allow_utensil: true,
    is_vegan: false,
  });
  let [publisherToBeCreated] = useState({
    name: "",
    languages: [],
    url: "",
    excluded_brands: [],
    excluded_pages: [],
    excluded_products: [],
    ad_type: "HOVERBOX",
    allow_alts: true,
    allow_inspo: true,
    allow_gl: false,
    allow_utensil: true,
    is_vegan: false,
  });
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleSubmit, setValue } = useForm({});
  const [errMsg, setErrMsg] = useState("");
  const [missingFieldErrMsg, setMissingFieldErrMsg] = useState("");
  const [urlErrMsg, setUrlErrMsg] = useState("");
  const [urlImgErrMsg, setUrlImgErrMsg] = useState("");
  const [showToastMessage, setShowToastMessage] = useState(false);

  const publisherArr = [publisher.name, publisher.languages, publisher.url];

  const successMessage = "Publisher was created successfully!";

  useEffect(() => {
    setErrMsg(errMsg);
  }, [publisher, errMsg]);

  useEffect(() => {
    getLanguages();
  }, []);

  async function getLanguages() {
    try {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      getAllLanguages(token).then((res) => {
        const langs = res.languages.map((item) => ({
          keyCode: item.iso,
          displayValue: item.name,
        }));
        setLanguages(langs);
      });
    } catch (error: any) {
      setErrMsg("An unexpected error occured.");
    }
  }

  function setLanguage(e) {
    updatePublisher({ ...publisher, language: e });
  }

  function setPublisherAllowAlts(e) {
    e.stopPropagation();
    updatePublisher({ ...publisher, allow_alts: e.target.checked });
  }

  function setPublisherAllowInspo(e) {
    e.stopPropagation();
    updatePublisher({ ...publisher, allow_inspo: e.target.checked });
  }

  function setPublisherAllowGl(e) {
    e.stopPropagation();
    updatePublisher({ ...publisher, allow_gl: e.target.checked });
  }

  function setPublisherAllowUtensil(e) {
    e.stopPropagation();
    updatePublisher({ ...publisher, allow_utensil: e.target.checked });
  }

  function setPublisherIsVegan(e) {
    e.stopPropagation();
    updatePublisher({ ...publisher, is_vegan: e.target.checked });
  }

  const updatePublisher = (data) => {
    setPublisher(() => ({
      name: data.name,
      languages: data.languages,
      url: data.url || "",
      excluded_brands: data.excluded_brands,
      excluded_pages: data.excluded_pages,
      excluded_products: data.excluded_products,
      ad_type: "HOVERBOX",
      allow_alts: data.allow_alts,
      allow_inspo: data.allow_inspo,
      allow_gl: data.allow_gl ? data.allow_gl : false,
      allow_utensil: data.allow_utensil,
      is_vegan: data.is_vegan
    }));
  };

  function _validateForm() {
    setErrMsg("");
    setMissingFieldErrMsg("");
    setUrlErrMsg("");
    setUrlImgErrMsg("");
    if (checkEmptyFields(publisherArr)) {
      setMissingFieldErrMsg("Please fill in all the required fields.");
      return false;
    } else if (!isValidUrl(publisher.url)) {
      setUrlErrMsg("The URL of the publisher does not have a valid format.");
      return false;
    }
    setErrMsg("");
    setMissingFieldErrMsg("");
    setUrlErrMsg("");
    setUrlImgErrMsg("");
    return true;
  }

  async function submitForm() {
    publisherToBeCreated = {
      ...publisher,
      languages: publisher.languages?.split(", "),
      excluded_brands:
        publisher.excluded_brands === ""
          ? []
          : publisher.excluded_brands?.split(", "),
      excluded_pages:
        publisher.excluded_pages === ""
          ? []
          : publisher.excluded_pages?.split(", "),
      excluded_products:
        publisher.excluded_products === ""
          ? []
          : publisher.excluded_products?.split(", "),
    };
    if (_validateForm()) {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      try {
        const createdPublisher = await createPublisher(
          token,
          publisherToBeCreated
        );

        if (createdPublisher.id) {
          setErrMsg(successMessage);
          setShowToastMessage(true);
          setTimeout(() => {
            onClose();
          }, 2000);
        } else if (createdPublisher.error_backend) {
          setErrMsg(
            JSON.parse(createdPublisher.error_backend.http_body).detail
          );
        }
      } catch (error: any) {
        setErrMsg("An unexpected error occured.");
      }
    }
  }

  return (
    <>
      <Toast
        onClose={() => setShowToastMessage(false)}
        show={showToastMessage}
        delay={4000}
        autohide
        animation
        style={{
          position: "fixed",
          top: 80,
          right: 20,
          zIndex: 9999,
          color: "white",
        }}
        bg={errMsg === successMessage ? "success" : "danger"}
      >
        <Toast.Body>
          {errMsg === successMessage ? successMessage : errMsg}
        </Toast.Body>
      </Toast>
      <form
        ref={formElem}
        id="publisherForm"
        className={style.my_container}
        onChange={handleSubmit(updatePublisher)}
      >
        <div className={style.subtitles}>
          <h2>Publisher information</h2>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="name"
              id="name"
              placeholder="Enter publisher's name"
              title="Publisher Name"
              mandatory={true}
              setValue={setValue}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
          </div>
          <div className={style.form_input_info}>
            <Dropdown
              name="languages"
              id="languages"
              placeholder="Choose the language of the publisher"
              title="Publisher's Language"
              mandatory={true}
              setValue={setValue}
              options={languages}
              handleClick={(e) => setLanguage(e)}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="url"
              id="url"
              placeholder="Enter the publisher's url"
              title="Publisher's URL"
              mandatory={true}
              setValue={setValue}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
            {urlErrMsg && <p className={style.error_msg}>{urlErrMsg}</p>}
          </div>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="excluded_brands"
              id="excluded_brands"
              placeholder="Enter the name of the brands to be excluded"
              title="Excluded Brands"
              mandatory={false}
              setValue={setValue}
            />
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="excluded_pages"
              id="excluded_pages"
              placeholder="Enter the name of the pages to be excluded"
              title="Excluded Pages"
              mandatory={false}
              setValue={setValue}
            />
          </div>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="excluded_products"
              id="excluded_products"
              placeholder="Enter the name of the products to be excluded"
              title="Excluded Products"
              mandatory={false}
              setValue={setValue}
            />
          </div>
        </div>
        <div>
          <div className={style.subtitles}>
            <h2>Promotion settings</h2>
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Allow Alts</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={publisher.allow_alts}
                  onChange={(e) => setPublisherAllowAlts(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Allow Inspo</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={publisher.allow_inspo}
                  onChange={(e) => setPublisherAllowInspo(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Allow GL</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={publisher.allow_gl}
                  onChange={(e) => setPublisherAllowGl(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Allow Utensil</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={publisher.allow_utensil}
                  onChange={(e) => setPublisherAllowUtensil(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Is Vegan</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={publisher.is_vegan}
                  onChange={(e) => setPublisherIsVegan(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>

        <div className={style.to_right}>
          <p className={style.error_msg} style={{ color: "red" }}>
            {errMsg !== successMessage && errMsg}
            {missingFieldErrMsg}
            {urlErrMsg}
            {urlImgErrMsg}
          </p>
          <SaveAndContinueButton
            name="saveButton"
            handleClick={() => submitForm()}
          ></SaveAndContinueButton>

          <button
            onClick={() => setShowCreatePublisher(!showCreatePublisher)}
            className={style.cancelButton}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default CreatePublisherForm;
