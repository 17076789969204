import PublisherInput from "../../shared/models/PublisherInput";
import { get, patch, post } from "./rest-service";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllPublishers(token: string) {
  return get(`${apiUrl}/admin/publisher/all`, token);
}

export function updatePublisher(
  token: string,
  publisher_id: number,
  active: boolean,
  excluded_brands: string[],
  excluded_pages: string[],
  excluded_products: string[],
  ad_type: string,
  allow_alts: boolean,
  allow_inspo: boolean,
  allow_gl: boolean,
  allow_utensil: boolean,
  is_vegan: boolean
) {
  return patch(
    `${apiUrl}/admin/publisher?id=${publisher_id}`,
    {
      active,
      excluded_brands,
      excluded_pages,
      excluded_products,
      ad_type,
      allow_alts,
      allow_inspo,
      allow_gl,
      allow_utensil,
      is_vegan,
    },
    token
  ).then((res) => {
    return res;
  });
}

export function clearPublisherImpressionsAlert(
  token: string,
  publisher_id: string
) {
  return patch(
    `${apiUrl}/admin/publisher/clear/alert?publisher_id=${publisher_id}`,
    {},
    token
  ).then((res) => {
    return res;
  });
}

export function createPublisher(token: string, publisher: PublisherInput) {
  return post(`${apiUrl}/admin/publisher`, publisher, token).then((res) => {
    return res;
  });
}
