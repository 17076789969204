import Card from "react-bootstrap/Card";
import style from "./AdminBrandProductSelection.module.scss";
import {
  getAllLanguages,
  updateBulkAdsState,
} from "../../../services/api-service/ad-service";
import { useAuth0 } from "@auth0/auth0-react";
import { Data, FilteredAdsData } from "./AdminBrandSelection";
import { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import EditAdPopup from "../AdminEditAdPopup";

type Props = {
  uniqueProducts: string[];
  selectedBrand: string;
  data: Data;
  isShowBrands: boolean;
  onToggle: (newState: boolean) => void;
  onProductClick: (selectedProduct: string) => void;
  getBrands: () => Promise<Data>;
};

const AdminProductSelection = ({
  uniqueProducts,
  selectedBrand,
  data,
  isShowBrands,
  onToggle,
  onProductClick,
  getBrands,
}: Props): JSX.Element => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [productStatus, setProductStatus] = useState<FilteredAdsData[]>();
  const [startStopLoading, setStartStopLoading] = useState(false);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [languages, setLanguages] = useState([
    {
      keyCode: "",
      displayValue: "",
    },
  ]);

  const adsStartedMessage = "Ads are started!";
  const adsStoppedMessage = "Ads are stopped!";

  const checkAdsActiveByProductName = async () => {
    const seletedProductsSet = new Set(uniqueProducts);
    const selectedBrandAndProducts = data.ads.filter(
      (ad) =>
        ad.brand_name === selectedBrand &&
        seletedProductsSet.has(ad.product_name)
    );

    const productStatusMap = new Map();

    for (const product of selectedBrandAndProducts) {
      const existingProducts = productStatusMap.get(product.product_name);
      if (!existingProducts) {
        productStatusMap.set(product.product_name, {
          advertiser_name: product.advertiser_name,
          brand_name: product.brand_name,
          product_name: product.product_name,
          ingredient_keyword: product.ingredient_keyword,
          type: product.type,
          impressions: product.impressions,
          active: product.active,
          url: product.url,
          language: product.language,
          hover_title: product.hover_title,
          hover_pic: product.hover_pic,
          hover_text: product.hover_text,
          suggestion_prefix: product.suggestion_prefix,
          suggestion_text: product.suggestion_text,
          inspo_prefix: product.inspo_prefix,
          inspo_text: product.inspo_text,
          utensil_prefix: product.utensil_prefix,
          utensil_text: product.utensil_text,
          hover_pic_portrait: product.hover_pic_portrait,
          country_ids: product.country_ids,
          country: product.countries,
          cpm: product.cpm,
        });
      } else if (!existingProducts.active && product.active) {
        existingProducts.active = true;
      }
    }

    const productStatus = Array.from(productStatusMap.values());
    const sortedProductStatus = productStatus.sort((a, b) =>
      a.product_name.localeCompare(b.product_name)
    );
    setProductStatus(sortedProductStatus);
  };

  const onStartStopClick = async (title: string) => {
    try {
      setStartStopLoading(true);

      const selectedBrandAndProducts = data.ads
        .filter((brand) => brand.brand_name === selectedBrand)
        .filter((product) => product.product_name === title);

      const checkSomeAdsActive = selectedBrandAndProducts.some(
        (product) => product.active === true
      );

      const adIdsToUpdate = selectedBrandAndProducts.map(
        (product) => product.id
      );

      const statusSwitcher = !checkSomeAdsActive;

      const token = await getAccessTokenSilently({ ignoreCache: true });

      updateBulkAdsState(token, adIdsToUpdate, statusSwitcher);
      getBrands();
      setTimeout(() => {
        setStartStopLoading(false);
      }, 1500);
      handleActiveChange(statusSwitcher);
    } catch (error) {
      alert(error);
    }
  };

  const selectedBrandAndProducts = (title: string) => {
    const selectedBrandAndProducts = data.ads
      .filter((brand) => brand.brand_name === selectedBrand)
      .filter((product) => product.product_name === title);
    return selectedBrandAndProducts;
  };

  const getSelectedProductIds = (title: string) => {
    const products = selectedBrandAndProducts(title);
    const adIdsToUpdate = products.map((product) => product.id);
    return adIdsToUpdate;
  };

  const getSelectedProductIngredients = (title: string) => {
    const products = selectedBrandAndProducts(title);
    const ingredients = products
      .map((product) => product.ingredient_keyword)
      .join(", ");
    return ingredients;
  };

  const handleActiveChange = (statusSwitcher) => {
    setToastMessage(statusSwitcher ? adsStartedMessage : adsStoppedMessage);
    setShowToastMessage(true);
  };

  async function getLanguages() {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    getAllLanguages(token)?.then((res) => {
      const langs = res.languages.map((item) => ({
        keyCode: item.iso,
        displayValue: item.name,
      }));
      setLanguages(langs);
    });
  }

  useEffect(() => {
    getBrands();
    getLanguages();
  }, []);

  useEffect(() => {
    checkAdsActiveByProductName();
  }, [data, selectedBrand]);

  const activeProducts = productStatus?.filter(
    (product) => product.active
  ).length;
  const inactiveProducts = productStatus?.filter(
    (product) => !product.active
  ).length;

  const renderEditPopUp = (product) => (
    <EditAdPopup
      calledFrom="AdminProductSelection"
      advertiser_name={product.advertiser_name}
      ingredient_keyword={product.ingredient_keyword}
      brand_name={product.brand_name}
      product_name={product.product_name}
      url={product.url}
      language={product.language}
      languages={languages}
      id={product.id}
      active={product.active}
      hover_title={product.hover_title}
      hover_pic={product.hover_pic}
      hover_pic_portrait={product.hover_pic_portrait}
      hover_text={product.hover_text}
      suggestion_prefix={product.suggestion_prefix}
      suggestion_text={product.suggestion_text}
      inspo_prefix={product.inspo_prefix}
      inspo_text={product.inspo_text}
      utensil_prefix={product.utensil_prefix}
      utensil_text={product.utensil_text}
      countries={product.countries}
      cpm={product.cpm}
      onSave={getBrands}
      onGetSelectedProductIds={getSelectedProductIds}
      onGetSelectedProductIngredients={getSelectedProductIngredients}
    />
  );

  return (
    <>
      <Toast
        onClose={() => setShowToastMessage(false)}
        show={showToastMessage}
        delay={1500}
        autohide
        animation
        style={{
          position: "fixed",
          top: 80,
          right: 20,
          zIndex: 9999,
          color: "white",
        }}
        bg={toastMessage === adsStartedMessage ? "success" : "danger"}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <div className={style.layout}>
        <h1>{selectedBrand}</h1>
        <div className={style.layout}>
          <div>
            <h2>Active</h2>
          </div>
          <div className={style.cardAlignment}>
            {activeProducts > 0 ? (
              productStatus
                ?.filter((product) => product.active)
                .map((product, id) => (
                  <Card key={id} className={style.cards}>
                    <span
                      data-testid="greenDot"
                      className={style.greenDot}
                    ></span>
                    <Card.Body className={style.cardBody}>
                      <Card.Title>{product?.product_name}</Card.Title>
                      <div className={style.productCardAlignment}>
                        <button
                          onClick={() => onProductClick(product?.product_name)}
                          className={style.cardButton}
                        >
                          View
                        </button>
                        <button
                          disabled={startStopLoading}
                          className={
                            product.active
                              ? style.stopButton
                              : style.startButton
                          }
                          onClick={() =>
                            onStartStopClick(product?.product_name)
                          }
                        >
                          {startStopLoading[product.product_name]
                            ? "Loading..."
                            : product.active
                            ? "Stop ads"
                            : "Start ads"}
                        </button>
                        {renderEditPopUp(product)}
                      </div>
                    </Card.Body>
                  </Card>
                ))
            ) : (
              <Card className={style.cards}>
                <Card.Body className={style.cardBody}>
                  No active products!
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
        <div className={style.layout}>
          <hr className={style.greyLine} />
          <div>
            <h2>Inactive</h2>
          </div>
          <div className={style.cardAlignment}>
            {inactiveProducts > 0 ? (
              productStatus
                ?.filter((product) => product.active === false)
                .map((product, id) => (
                  <Card key={id} className={style.cards}>
                    <Card.Body className={style.inactiveCardBody}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Card.Title>{product?.product_name}</Card.Title>
                        <span
                          data-testid="redDot"
                          className={style.redDot}
                        ></span>
                      </div>
                      <div className={style.productCardAlignment}>
                        <button
                          onClick={() => onProductClick(product?.product_name)}
                          className={style.cardButton}
                        >
                          View
                        </button>
                        <button
                          disabled={startStopLoading}
                          className={
                            product.active
                              ? style.stopButton
                              : style.startButton
                          }
                          onClick={() =>
                            onStartStopClick(product?.product_name)
                          }
                        >
                          {startStopLoading[product.product_name]
                            ? "Loading..."
                            : product.active
                            ? "Stop ads"
                            : "Start ads"}
                        </button>
                        {renderEditPopUp(product)}
                      </div>
                    </Card.Body>
                  </Card>
                ))
            ) : (
              <Card className={style.cards}>
                <Card.Body className={style.cardBody}>
                  No inactive products!
                </Card.Body>
              </Card>
            )}
          </div>
        </div>

        <div>
          <button
            className={style.cancelButton}
            onClick={() => onToggle(!isShowBrands)}
          >
            Back to brands
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminProductSelection;
