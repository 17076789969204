import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import style from "./AdminEditAdPopup.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getCountrySearch,
  updateAd,
  updateBulkAds,
} from "../../services/api-service/ad-service";
import { checkEmptyFields, isValidUrl } from "../../services/util-service";
import Toast from "react-bootstrap/Toast";
import FormInputInformation from "../../shared/components/molecules/form-input-information/FormInputInformation";
import { useForm } from "react-hook-form";
import Dropdown from "../../shared/components/atoms/Dropdown/Dropdown";
import TextAreaInput from "../../shared/components/atoms/TextAreaInput/TextAreaInput";

function EditAdPopup(props: EditAdPopupProps) {
  const [show, setShow] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const [languageValue, setLanguageValue] = useState("");
  const [hoverTitleValue, setHoverTitleValue] = useState("");
  const [hoverPicValue, setHoverPicValue] = useState("");
  const [hoverPicPortraitValue, setHoverPicPortraitValue] = useState(true);
  const [hoverTextValue, setHoverTextValue] = useState("");
  const [adAlternativeToggle, setAdAlternativeToggle] = useState(
    props.suggestion_prefix?.length > 0 || props.suggestion_text?.length > 0
      ? true
      : false
  );
  const [suggestionPrefixValue, setSuggestionPrefixValue] = useState("");
  const [suggestionTextValue, setSuggestionTextValue] = useState("");
  const [inspoToggle, setInspoToggle] = useState(
    props.inspo_prefix?.length > 0 || props.inspo_text?.length > 0
      ? true
      : false
  );
  const [inspoPrefixValue, setInspoPrefixValue] = useState("");
  const [inspoTextValue, setInspoTextValue] = useState("");
  const [utensilToggle, setUtensilToggle] = useState(
    props.utensil_prefix?.length > 0 || props.utensil_text?.length > 0
      ? true
      : false
  );
  const [utensilPrefixValue, setUtensilPrefixValue] = useState("");
  const [utensilTextValue, setUtensilTextValue] = useState("");
  const [cpmValue, setCpmValue] = useState("");
  //const [locationsValue, setLocationsValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [missingFieldErrMsg, setMissingFieldErrMsg] = useState("");
  const [urlErrMsg, setUrlErrMsg] = useState("");
  const [urlImgErrMsg, setUrlImgErrMsg] = useState("");
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const { setValue } = useForm({});

  const handleUrlChange = (event) => {
    setUrlValue(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguageValue(event);
  };

  const handleHoverTitleChange = (event) => {
    setHoverTitleValue(event.target.value);
  };

  const handleHoverPicChange = (event) => {
    setHoverPicValue(event.target.value);
  };

  const handleHoverPicPortraitChange = (event) => {
    setHoverPicPortraitValue(event.target.checked);
  };

  const handleAdAlternativeChange = (event) => {
    setAdAlternativeToggle(event.target.checked);
    setSuggestionPrefixValue(
      event.target.checked ? props.suggestion_prefix : ""
    );
    setSuggestionTextValue(event.target.checked ? props.suggestion_text : "");
  };

  const handleHoverTextChange = (event) => {
    setHoverTextValue(event.target.value);
  };

  const handleSuggestionPrefixChange = (event) => {
    setSuggestionPrefixValue(event.target.value);
  };

  const handleSuggestionTextChange = (event) => {
    setSuggestionTextValue(event.target.value);
  };

  const handleInspoChange = (event) => {
    setInspoToggle(event.target.checked);
    setInspoPrefixValue(event.target.checked ? props.inspo_prefix : "");
    setInspoTextValue(event.target.checked ? props.inspo_text : "");
  };

  const handleInspoPrefixChange = (event) => {
    setInspoPrefixValue(event.target.value);
  };

  const handleInspoTextChange = (event) => {
    setInspoTextValue(event.target.value);
  };

  const handleUtensilChange = (event) => {
    setUtensilToggle(event.target.checked);
    setUtensilPrefixValue(event.target.checked ? props.utensil_prefix : "");
    setUtensilTextValue(event.target.checked ? props.utensil_text : "");
  };

  const handleUtensilPrefixChange = (event) => {
    setUtensilPrefixValue(event.target.value);
  };

  const handleUtensilTextChange = (event) => {
    setUtensilTextValue(event.target.value);
  };

  const handleCpmChange = (event) => {
    setCpmValue(event.target.value);
  };

  const handleClose = () => {
    setUrlValue(props.url);
    setHoverTitleValue(props.hover_title);
    setHoverPicValue(props.hover_pic);
    setHoverPicPortraitValue(props.hover_pic_portrait);
    setHoverTextValue(props.hover_text);
    setSuggestionPrefixValue(props.suggestion_prefix);
    setSuggestionTextValue(props.suggestion_text);
    setInspoPrefixValue(props.inspo_prefix);
    setInspoTextValue(props.inspo_text);
    setUtensilPrefixValue(props.utensil_prefix);
    setUtensilTextValue(props.utensil_text);
    setCpmValue(props.cpm);
    /* props.countries.map((i) => {
      setLocationsValue(i.id);
    }); */
    setSearchInput("");
    setErrMsg("");
    setMissingFieldErrMsg("");
    setUrlErrMsg("");
    setUrlImgErrMsg("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setUrlValue(props.url);
    setHoverTitleValue(props.hover_title);
    setHoverPicValue(props.hover_pic);
    setHoverPicPortraitValue(props.hover_pic_portrait);
    setHoverTextValue(props.hover_text);
    setSuggestionPrefixValue(props.suggestion_prefix);
    setSuggestionTextValue(props.suggestion_text);
    setInspoPrefixValue(props.inspo_prefix);
    setInspoTextValue(props.inspo_text);
    setUtensilPrefixValue(props.utensil_prefix);
    setUtensilTextValue(props.utensil_text);
    setLanguageValue(props.language);
    setCpmValue(props.cpm);
    /*    const tempLoc = [];
    props.countries.map((i) => {
      tempLoc.push((i.name, i.id));
      setSelectedOptions(tempLoc);
    }); */
  }, []);

  const editAd = async (adId, adActive) => {
    setLoading(true);
    if (_validateFields()) {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      const updatedAd = await updateAd(
        token,
        adId,
        urlValue,
        languageValue,
        adActive,
        hoverTitleValue,
        hoverPicValue,
        hoverTextValue,
        suggestionPrefixValue,
        suggestionTextValue,
        inspoPrefixValue,
        inspoTextValue,
        utensilPrefixValue,
        utensilTextValue,
        hoverPicPortraitValue,
        selectedOptions,
        parseFloat(cpmValue),
      );
      if (updatedAd) {
        setSuccessMessage("Ad updated successfully!");
        setErrMsg(successMessage);
        props.onSave();
        setShowToastMessage(true);
        setShow(false);
        setLoading(false);
        setErrMsg("");
      } else if (updatedAd.error_backend) {
        setErrMsg(JSON.parse(updatedAd.error_backend.http_body).detail);
        setLoading(false);
      }
    }
  };

  const bulkEditAd = async (title) => {
    setLoading(true);
    if (_validateFields()) {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      const adIds = await props.onGetSelectedProductIds(title);
      const adActive = false;
      const updatedBulkAd = await updateBulkAds(
        token,
        hoverTitleValue,
        hoverTextValue,
        hoverPicValue,
        hoverPicPortraitValue,
        urlValue,
        languageValue,
        adActive,
        suggestionPrefixValue,
        suggestionTextValue,
        inspoPrefixValue,
        inspoTextValue,
        utensilPrefixValue,
        utensilTextValue,
        adIds,
        parseFloat(cpmValue)
      );
      if (updatedBulkAd) {
        setSuccessMessage("Ads updated successfully!");
        setErrMsg(successMessage);
        setWarningMessage("Ads are deactivated!");
        props.onSave();
        setShowToastMessage(true);
        setShow(false);
        setLoading(false);
        setErrMsg("");
      } else if (updatedBulkAd.error_backend) {
        setErrMsg(JSON.parse(updatedBulkAd.error_backend.http_body).detail);
        setLoading(false);
      }
    }
  };

  const adArr = [
    urlValue,
    languageValue,
    hoverTitleValue ? hoverTitleValue && hoverPicValue && hoverTextValue : null,
    adAlternativeToggle ? suggestionPrefixValue : null,
    adAlternativeToggle ? suggestionTextValue : null,
    inspoToggle ? inspoPrefixValue : null,
    inspoToggle ? inspoTextValue : null,
    utensilToggle ? utensilPrefixValue : null,
    utensilToggle ? utensilTextValue : null,
    cpmValue
  ];

  function _validateFields() {
    setErrMsg("");
    setMissingFieldErrMsg("");
    setUrlErrMsg("");
    setUrlImgErrMsg("");
    if (checkEmptyFields(adArr)) {
      setMissingFieldErrMsg("Please fill in all the required fields.");
      setLoading(false);
      return false;
    } else if (!isValidUrl(urlValue)) {
      setUrlErrMsg("The URL of the ad does not have a valid format.");
      setLoading(false);
      return false;
    } else if (hoverTitleValue && !isValidUrl(hoverPicValue)) {
      setUrlImgErrMsg("The URL of the image does not have a valid format.");
      setLoading(false);
      return false;
    }
    setErrMsg("");
    setMissingFieldErrMsg("");
    setUrlErrMsg("");
    setUrlImgErrMsg("");
    return true;
  }

  const [matchingLocations, setMatchingLocations] = useState([
    {
      value: 0,
      label: "",
    },
  ]);

  // the 5 consts below are used for the locations field
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  /*   const [existingLocations, setExistingLocations] = useState(
    props.countries.map((i) => {
      return {
        value: i.id,
        label: i.name,
      };
    })
  ); */

  // everything below this point is for the locations field
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (searchInput != "") {
      setDropdownVisible(true);
    } else if (searchInput == "") {
      setDropdownVisible(false);
    }
  }, [searchInput]);

  const handleOptionChange = (event) => {
    if (!selectedOptions.includes(event.target.value)) {
      setSelectedOptions((oldArray) => [...oldArray, +event.target.value]);
    }
    setSearchInput("");
  };

  const filteredOptions = matchingLocations.filter((option) =>
    option.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleRemoveOption = (val) => {
    setSelectedOptions((oldArray) => oldArray.filter((value) => value !== val));
  };
  // until this point

  useEffect(() => {
    if (searchInput !== "") {
      searchCountry(searchInput);
    }
  }, [searchInput]);

  async function searchCountry(inputValue: string) {
    try {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      await getCountrySearch(token, inputValue).then((res) => {
        const matchLocs = res.countries.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setMatchingLocations(matchLocs);
      });
    } catch (error: any) {
      setErrMsg("An unexpected error occured.");
    }
  }

  const collectIngredients = (title) => {
    if (props.calledFrom === "AdminProductSelection") {
      const ingredients = props.onGetSelectedProductIngredients(title);
      return ingredients;
    } else {
      return props.ingredient_keyword;
    }
  };

  //const allLocations = [...existingLocations, ...matchingLocations];
  /* const uniqueValues = allLocations.reduce((newLoc, currentLoc) => {
    if (!newLoc.includes(currentLoc.value)) {
      newLoc.push(currentLoc.value);
    }
    return newLoc;
  }, []); */

  return (
    <>
      <Toast
        onClose={() => setShowToastMessage(false)}
        show={showToastMessage}
        delay={3000}
        autohide
        animation
        style={{
          position: "fixed",
          top: 80,
          right: 20,
          zIndex: 9999,
          color: "white",
        }}
        bg={errMsg !== successMessage ? "success" : "danger"}
      >
        <Toast.Body>
          {errMsg !== successMessage ? successMessage : errMsg}
        </Toast.Body>
      </Toast>
      {warningMessage && props.active && (
        <Toast
          onClose={() => setShowToastMessage(false)}
          show={showToastMessage}
          delay={3000}
          autohide
          animation
          style={{
            position: "fixed",
            top: 135,
            right: 20,
            zIndex: 9999,
            color: "white",
          }}
          bg="warning"
        >
          <Toast.Body>{warningMessage}</Toast.Body>
        </Toast>
      )}
      <button className={style.cardButton} onClick={handleShow}>
        {props.calledFrom === "AdminAdsTable" ? "Edit ad" : "Edit ads"}
      </button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit promotion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className={style.my_container}>
            <div className={style.subtitles}>
              <h2>Product information</h2>
            </div>
            <div className={style.my_row}>
              <div className={style.form_input_info}>
                <FormInputInformation
                  name="advertiser_name"
                  id="advertiser_name"
                  placeholder="Enter advertiser's name"
                  title="Advertiser Name"
                  mandatory={false}
                  disabled={true}
                  value={props.advertiser_name}
                  setValue={setValue}
                />
              </div>
              <div className={style.form_input_info}>
                <FormInputInformation
                  name="brand_name"
                  id="brand_name"
                  placeholder="Enter the brand's name"
                  title="Brand Name"
                  mandatory={false}
                  disabled={true}
                  value={props.brand_name}
                  setValue={setValue}
                />
              </div>
            </div>
            <div className={style.my_row}>
              <div className={style.form_input_info}>
                <FormInputInformation
                  name="product_name"
                  id="product_name"
                  placeholder="Enter the name of the product"
                  title="Product Name"
                  mandatory={false}
                  disabled={true}
                  value={props.product_name}
                  setValue={setValue}
                />
              </div>
              <div className={style.form_input_info}>
                <FormInputInformation
                  name="ingredient_keywords"
                  id="ingredient_keywords"
                  placeholder="Enter the ingredients, eg: ui, uien, grote uien, middel uien"
                  title="Ingredients"
                  mandatory={false}
                  disabled={true}
                  value={collectIngredients(props.product_name)}
                  setValue={setValue}
                  hoverText="Input the ingredients linked to this ad, comma separated."
                />
              </div>
            </div>
            <div className={style.my_row}>
              <div className={style.form_input_info}>
                <FormInputInformation
                  name="url"
                  id="url"
                  placeholder="Enter the brand's url"
                  title="Brand's URL"
                  mandatory={true}
                  disabled={false}
                  value={urlValue}
                  handleChange={handleUrlChange}
                />
                {missingFieldErrMsg && (
                  <p className={style.error_msg}>{missingFieldErrMsg}</p>
                )}
                {urlErrMsg && <p className={style.error_msg}>{urlErrMsg}</p>}
              </div>
              <div className={style.form_input_info}>
                <Dropdown
                  name="languages"
                  id="languages"
                  placeholder="Choose the language of your ad"
                  title="Ad language"
                  mandatory={true}
                  setValue={setValue}
                  options={props.languages}
                  handleClick={(e) => handleLanguageChange(e)}
                  value={props.language}
                />
                {missingFieldErrMsg && (
                  <p className={style.error_msg}>{missingFieldErrMsg}</p>
                )}
              </div>
            </div>
            <div className={style.my_row}>
              <div className={style.form_input_info}>
                <FormInputInformation
                  name="cpm"
                  id="cpm"
                  placeholder="Enter the CPM"
                  title="CPM"
                  disabled={false}
                  mandatory={true}
                  value={cpmValue}
                  handleChange={handleCpmChange}
                />
              </div>
            </div>
            <div className={style.subtitles}>
              <h2>Hoverbox information</h2>
            </div>
            <div className={style.my_row}>
              <div className={style.form_input_info}>
                <FormInputInformation
                  name="hover_title"
                  id="hover_title"
                  placeholder="Enter the title of the ad"
                  title="Ad title"
                  mandatory={false}
                  value={hoverTitleValue}
                  handleChange={handleHoverTitleChange}
                />
              </div>

              <div className={style.form_input_info}>
                <FormInputInformation
                  name="hover_pic"
                  id="hover_pic"
                  placeholder="Enter the url of the ad's image"
                  title="Image URL"
                  mandatory={hoverTitleValue === "" ? false : true}
                  value={hoverPicValue}
                  handleChange={handleHoverPicChange}
                />
                {missingFieldErrMsg && (
                  <p className={style.error_msg}>{missingFieldErrMsg}</p>
                )}
                {urlImgErrMsg && (
                  <p className={style.error_msg}>{urlImgErrMsg}</p>
                )}
              </div>
            </div>
            <div className={style.my_row}>
              <TextAreaInput
                name="hover_text"
                id="hover_text"
                placeholder="Enter the description of the ad"
                title="Description"
                maxLength={110}
                mandatory={hoverTitleValue === "" ? false : true}
                hoverText="Input the description of the ad."
                value={hoverTextValue}
                handleChange={handleHoverTextChange}
              />
              {missingFieldErrMsg && (
                <p className={style.error_msg}>{missingFieldErrMsg}</p>
              )}
            </div>
            <div className={style.subtitles}>
              <h2>Additional information</h2>
            </div>
            <div className={style.my_row}>
              <div className={style.form_input_info}>
                <label>
                  <span>Hover Pic Portrait</span>
                  <label className={style.toggle}>
                    <input
                      className={style.toggle}
                      type="checkbox"
                      checked={hoverPicPortraitValue}
                      onChange={(e) => handleHoverPicPortraitChange(e)}
                    />
                    <span className={`${style.slider} ${style.round}`}></span>
                  </label>
                </label>
              </div>
            </div>
            <div hidden={inspoToggle || utensilToggle} className={style.my_row}>
              <div className={style.form_input_info}>
                <label>
                  <span>Alternative Suggestion</span>
                  <label className={style.toggle}>
                    <input
                      className={style.toggle}
                      type="checkbox"
                      checked={adAlternativeToggle}
                      disabled={true}
                      onChange={(e) => handleAdAlternativeChange(e)}
                    />
                    <span className={`${style.slider} ${style.round}`}></span>
                  </label>
                </label>
              </div>
            </div>
            {adAlternativeToggle && (
              <div className={style.my_row}>
                <div className={style.form_input_info}>
                  <FormInputInformation
                    name="suggestion_prefix"
                    id="suggestion_prefix"
                    placeholder="Enter the prefix for the Alternative Promotion"
                    title="Alternative Promotion prefix"
                    mandatory={true}
                    value={suggestionPrefixValue}
                    handleChange={handleSuggestionPrefixChange}
                  />
                  {missingFieldErrMsg && (
                    <p className={style.error_msg}>{missingFieldErrMsg}</p>
                  )}
                </div>
                <div className={style.form_input_info}>
                  <FormInputInformation
                    name="suggestion_text"
                    id="suggestion_text"
                    placeholder="Enter the text for the Alternative Promotion"
                    title="Alternative Promotion text"
                    mandatory={true}
                    value={suggestionTextValue}
                    handleChange={handleSuggestionTextChange}
                  />
                  {missingFieldErrMsg && (
                    <p className={style.error_msg}>{missingFieldErrMsg}</p>
                  )}
                </div>
              </div>
            )}

            <div
              hidden={adAlternativeToggle || utensilToggle}
              className={style.my_row}
            >
              <div className={style.form_input_info}>
                <label>
                  <span>Inspirational Tip</span>
                  <label className={style.toggle}>
                    <input
                      className={style.toggle}
                      type="checkbox"
                      checked={inspoToggle}
                      disabled={true}
                      onChange={(e) => handleInspoChange(e)}
                    />
                    <span className={`${style.slider} ${style.round}`}></span>
                  </label>
                </label>
              </div>
            </div>
            {inspoToggle && (
              <div className={style.my_row}>
                <div className={style.form_input_info}>
                  <FormInputInformation
                    name="inspo_prefix"
                    id="inspo_prefix"
                    placeholder="Enter the prefix for the Inspirational Tip"
                    title="Inspirational Tip prefix"
                    mandatory={true}
                    value={inspoPrefixValue}
                    handleChange={handleInspoPrefixChange}
                  />
                  {missingFieldErrMsg && (
                    <p className={style.error_msg}>{missingFieldErrMsg}</p>
                  )}
                </div>
                <div className={style.form_input_info}>
                  <FormInputInformation
                    name="inspo_text"
                    id="inspo_text"
                    placeholder="Enter the text for the Inspirational Tip"
                    title="Inspirational Tip text"
                    mandatory={true}
                    value={inspoTextValue}
                    handleChange={handleInspoTextChange}
                  />
                  {missingFieldErrMsg && (
                    <p className={style.error_msg}>{missingFieldErrMsg}</p>
                  )}
                </div>
              </div>
            )}

            <div
              hidden={adAlternativeToggle || inspoToggle}
              className={style.form_input_info}
            >
              <label>
                <span>Utensil promotion</span>
                <label className={style.toggle}>
                  <input
                    className={style.toggle}
                    type="checkbox"
                    checked={utensilToggle}
                    disabled={true}
                    onChange={(e) => handleUtensilChange(e)}
                  />
                  <span className={`${style.slider} ${style.round}`}></span>
                </label>
              </label>
            </div>

            {utensilToggle && (
              <div className={style.my_row}>
                <div className={style.form_input_info}>
                  <FormInputInformation
                    name="utensil_prefix"
                    id="utensil_prefix"
                    placeholder="Enter the prefix for the utensil promotion"
                    title="Utensil promotion prefix"
                    mandatory={true}
                    value={utensilPrefixValue}
                    handleChange={handleUtensilPrefixChange}
                  />
                  {missingFieldErrMsg && (
                    <p className={style.error_msg}>{missingFieldErrMsg}</p>
                  )}
                </div>
                <div className={style.form_input_info}>
                  <FormInputInformation
                    name="utensil_text"
                    id="utensil_text"
                    placeholder="Enter the text for the utensil promotion"
                    title="Utensil promotion text"
                    mandatory={true}
                    value={utensilTextValue}
                    handleChange={handleUtensilTextChange}
                  />
                  {missingFieldErrMsg && (
                    <p className={style.error_msg}>{missingFieldErrMsg}</p>
                  )}
                </div>
              </div>
            )}
            <div hidden className={style.my_row}>
              <Form.Group className={style.form_input_info}>
                <Form.Label>Locations</Form.Label>
                <div>
                  <Form.Control
                    className={style.input}
                    type="text"
                    value={searchInput}
                    onChange={handleSearchInput}
                    placeholder="Choose one or more locations..."
                  />
                  {dropdownVisible && (
                    <select
                      multiple={true}
                      className={style.dropdown}
                      size={Math.min(filteredOptions.length, 6)}
                    >
                      {filteredOptions.map((option, index) => {
                        return (
                          !selectedOptions.includes(option.value) && (
                            <option
                              value={option.value}
                              key={index}
                              onClick={handleOptionChange}
                              className={style.option}
                            >
                              {option.label}
                            </option>
                          )
                        );
                      })}
                    </select>
                  )}
                </div>
                {/*  <div>
                  {uniqueValues.map((value) => {
                    const location = allLocations.find(
                      (o) => o.value === value
                    );
                    if (!location) return null;

                    return (
                      selectedOptions.includes(value) && (
                        <div className={style.tag}>
                          <button
                            className={style.tag_button}
                            onClick={() => handleRemoveOption(value)}
                          >
                            x
                          </button>
                          {location.label}
                        </div>
                      )
                    );
                  })}
                </div> */}
              </Form.Group>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className={style.my_container}>
            <p
              className={style.error_msg}
              style={{ color: errMsg === successMessage ? "green" : "red" }}
            >
              {errMsg + " "}
            </p>
          </div>
          <button className={style.cancelButton} onClick={handleClose}>
            Close
          </button>
          <button
            disabled={loading}
            className={style.cardButton}
            onClick={() =>
              props.calledFrom === "AdminAdsTable"
                ? editAd(props.id, props.active)
                : bulkEditAd(props.product_name)
            }
          >
            {loading ? "Loading..." : "Save changes"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export interface EditAdPopupProps {
  calledFrom: string;
  advertiser_name: string;
  ingredient_keyword: string;
  brand_name: string;
  product_name: string;
  url: string;
  language: string;
  id: number;
  active: boolean;
  languages: Array<EditAdLanguageProps>;
  hover_title: string;
  hover_pic: string;
  hover_pic_portrait: boolean;
  hover_text: string;
  suggestion_prefix: string;
  suggestion_text: string;
  inspo_prefix: string;
  inspo_text: string;
  utensil_prefix: string;
  utensil_text: string;
  country_ids?: number[];
  countries;
  cpm?: string;
  onSave: () => void;
  onGetSelectedProductIds?: (title: string) => number[];
  onGetSelectedProductIngredients?: (title: string) => string;
}

interface EditAdLanguageProps {
  keyCode: string;
  displayValue: string;
}

export default EditAdPopup;
