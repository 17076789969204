import { useState } from "react";
import { Modal } from "react-bootstrap";
import AdminPublisherTable from "./AdminPublisherTable";
import CreatePublisherForm from "../../shared/components/forms/create-publisher-form/CreatePublisherForm";

const PublisherOverview = () => {
  const [showCreatePublisher, setShowCreatePublisher] = useState(false);
  const [updatePublishers, setUpdatePublishers] = useState(false);

  const createPublisher = () => {
    setShowCreatePublisher(true);
  };

  const onClosed = () => {
    setUpdatePublishers(!updatePublishers);
    setShowCreatePublisher(false);
  };

  return (
    <>
      <Modal
        show={showCreatePublisher}
        fullscreen
        animation={false}
        onHide={() => onClosed()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create publisher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatePublisherForm
            showCreatePublisher={showCreatePublisher}
            setShowCreatePublisher={setShowCreatePublisher}
            onClose={onClosed}
          />
        </Modal.Body>
      </Modal>
      <div>
        <button onClick={createPublisher} className="create-ad-button">
          Create publisher
        </button>
      </div>
      <hr />
      <>
        <AdminPublisherTable updatePublishers={updatePublishers} />
      </>
    </>
  );
};

export default PublisherOverview;
