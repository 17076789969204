import { useEffect, useState } from "react";
import {
  getAllAds,
  getAllLanguages,
  updateAd,
} from "../../services/api-service/ad-service";
import { useAuth0 } from "@auth0/auth0-react";
import * as ReactBootStrap from "react-bootstrap";
import AdminEditAdPopup from "./AdminEditAdPopup";
import style from "./AdminAdsTable.module.scss";
import { FilteredAdsData } from "./brand-product-selection/AdminBrandSelection";
import Toast from "react-bootstrap/Toast";

type Props = {
  filterAdsForProducts: FilteredAdsData[];
  isShowProducts: boolean;
  onToggle: (newState: boolean) => void;
};

const AdminAdsTable = ({
  filterAdsForProducts,
  isShowProducts,
  onToggle,
}: Props): JSX.Element => {
  const [rows, setRows] = useState([]);
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [startStopLoading, setStartStopLoading] = useState({});
  const [error, setError] = useState();
  const [languages, setLanguages] = useState([
    {
      keyCode: "",
      displayValue: "",
    },
  ]);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null,
  });

  const adIsStartedMessage = "Ad is started!";
  const adIsStoppedMessage = "Ad is stopped!";

  const sortedRows = rows.slice().sort((a, b) => {
    if (!sortConfig.key) return 0;

    const direction = sortConfig.direction === "ascending" ? 1 : -1;

    if (a[sortConfig.key] < b[sortConfig.key]) {
      return -1 * direction;
    } else if (a[sortConfig.key] > b[sortConfig.key]) {
      return 1 * direction;
    } else {
      return 0;
    }
  });

  const onSort = (key) => {
    let direction = "ascending";
    // If the user clicks on the same column, reverse the sort direction
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getAds = async () => {
    try {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      const res = await getAllAds(token);
      const selectedData = res.ads.filter((ad) =>
        filterAdsForProducts.some(
          (product) =>
            product.brand_name === ad.brand_name &&
            product.product_name === ad.product_name
        )
      );

      const sortedRows = selectedData.sort((a, b) => {
        // Compare by advertiser_name first
        if (a.advertiser_name < b.advertiser_name) return -1;
        if (a.advertiser_name > b.advertiser_name) return 1;
        // Compare by brand_name
        if (a.product_name < b.product_name) return -1;
        if (a.product_name > b.product_name) return 1;
        // If all three properties are equal, maintain the order
        return 0;
      });
      setIsLoaded(true);
      setRows(sortedRows);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    getAds();
    getLanguages();
  }, []);

  const handleActiveChange = (adActive) => {
    setToastMessage(adActive ? adIsStartedMessage : adIsStoppedMessage);
    setShowToastMessage(true);
  };

  async function getLanguages() {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    getAllLanguages(token).then((res) => {
      const langs = res.languages.map((item) => ({
        keyCode: item.iso,
        displayValue: item.name,
      }));
      setLanguages(langs);
    });
  }

  async function toggleActive(
    adId,
    adUrl,
    adLanguage,
    adActive,
    adHoverTitle,
    adHoverPic,
    adHoverText,
    suggestionPrefix,
    suggestionText,
    inspoPrefix,
    inspoText,
    utensilPrefix,
    utensilText,
    hoverPicPortrait,
    adLocations,
    adCpm
  ) {
    setStartStopLoading({ [adId]: true });
    adActive = !adActive;
    handleActiveChange(adActive);
    const token = await getAccessTokenSilently({ ignoreCache: true });
    updateAd(
      token,
      adId,
      adUrl,
      adLanguage,
      adActive,
      adHoverTitle,
      adHoverPic,
      adHoverText,
      suggestionPrefix,
      suggestionText,
      inspoPrefix,
      inspoText,
      utensilPrefix,
      utensilText,
      hoverPicPortrait,
      adLocations,
      adCpm
    );
    getAds();
    setStartStopLoading({ [adId]: false });
  }

  return (
    <>
      <Toast
        onClose={() => setShowToastMessage(false)}
        show={showToastMessage}
        delay={3000}
        autohide
        animation
        style={{
          position: "fixed",
          top: 80,
          right: 20,
          zIndex: 9999,
          color: "white",
        }}
        bg={toastMessage === adIsStartedMessage ? "success" : "danger"}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      {error ? (
        <div> Error: {error}</div>
      ) : !isLoaded ? (
        <div>
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <ReactBootStrap.Table striped hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th onClick={() => onSort("advertiser_name")}>Advertiser</th>
                <th onClick={() => onSort("brand_name")}>Brand</th>
                <th onClick={() => onSort("product_name")}>Product</th>
                <th onClick={() => onSort("ingredient_keyword")}>
                  Matched ingredient
                </th>
                <th>Type</th>
                <th>CPM</th>
                <th>Vegan</th>
                <th onClick={() => onSort("impressions")}>Impressions</th>
                <th>State</th>
                <th>Edit ad</th>
              </tr>
            </thead>
            <tbody>
              {sortedRows?.map((item, id) => (
                <>
                  <tr key={id}>
                    <td>{item.id}</td>
                    <td>{item.advertiser_name}</td>
                    <td>{item.brand_name}</td>
                    <td>{item.product_name}</td>
                    <td>{item.ingredient_keyword}</td>
                    <td>
                      {item.is_alternative
                        ? "Alternative"
                        : item.is_inspo
                        ? "Inspo"
                        : item.is_utensil
                        ? "Utensil"
                        : "Swap"}
                    </td>
                    <td>{item.cpm}</td>
                    <td>{item.is_vegan ? "Vegan" : "Non-vegan"}</td>
                    <td>{item.impressions}</td>
                    <td>
                      <button
                        key={item.id}
                        disabled={startStopLoading[item.id]}
                        className={
                          item.active ? style.stopButton : style.startButton
                        }
                        onClick={() => {
                          toggleActive(
                            item.id,
                            item.url,
                            item.language,
                            item.active,
                            item.hover_title,
                            item.hover_pic,
                            item.hover_text,
                            item.suggestion_prefix,
                            item.suggestion_text,
                            item.inspo_prefix,
                            item.inspo_text,
                            item.utensil_prefix,
                            item.utensil_text,
                            item.hover_pic_portrait,
                            item.country_ids,
                            item.cpm
                          );
                        }}
                      >
                        {startStopLoading[item.id]
                          ? "Loading..."
                          : item.active
                          ? "Stop ad"
                          : "Start ad"}
                      </button>
                    </td>
                    <td>
                      <AdminEditAdPopup
                        calledFrom="AdminAdsTable"
                        advertiser_name={item.advertiser_name}
                        ingredient_keyword={item.ingredient_keyword}
                        brand_name={item.brand_name}
                        product_name={item.product_name}
                        url={item.url}
                        language={item.language}
                        languages={languages}
                        id={item.id}
                        active={item.active}
                        hover_title={item.hover_title}
                        hover_pic={item.hover_pic}
                        hover_pic_portrait={item.hover_pic_portrait}
                        hover_text={item.hover_text}
                        suggestion_prefix={item.suggestion_prefix}
                        suggestion_text={item.suggestion_text}
                        inspo_prefix={item.inspo_prefix}
                        inspo_text={item.inspo_text}
                        utensil_prefix={item.utensil_prefix}
                        utensil_text={item.utensil_text}
                        countries={item.countries}
                        cpm={item.cpm}
                        onSave={getAds}
                      />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </ReactBootStrap.Table>
          <button
            className={style.cancelButton}
            onClick={() => onToggle(!isShowProducts)}
          >
            Back to products
          </button>
        </>
      )}
    </>
  );
};

export default AdminAdsTable;
