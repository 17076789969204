import { useEffect, useState } from "react";
import { getAllAds } from "../../../services/api-service/ad-service";
import { useAuth0 } from "@auth0/auth0-react";
import Card from "react-bootstrap/Card";
import style from "./AdminBrandProductSelection.module.scss";
import AdminAdsTable from "../AdminAdsTable";
import AdminProductSelection from "./AdminProductSelection";

export interface Brands {
  brand_name: string;
}

type BrandStatus = {
  brandName: string;
  isActive: boolean;
}[];

export interface Products {
  product_name: string;
}

export interface FilteredAdsData {
  id: number;
  advertiser_name: string;
  brand_name: string;
  product_name: string;
  ingredient_keyword: string;
  type: string;
  impressions: number;
  active: boolean;
  ad_ids?: number[];
  url: string;
  language: string;
  hover_title: string;
  hover_pic: string;
  hover_text: string;
  hover_pic_portrait: boolean;
  suggestion_prefix: string;
  suggestion_text: string;
  inspo_prefix: string;
  inspo_text: string;
  utensil_prefix: string;
  utensil_text: string;
  country_ids?: number[];
  countries;
  cpm?: number;
  publisher_ids?: number[];
}

export interface Data {
  ads: FilteredAdsData[];
}

const AdminBrandSelection = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<Data>();

  const [brandStatus, setBrandStatus] = useState<BrandStatus>();
  const [isShowBrands, setIsShowBrands] = useState<boolean>(true);
  const [isShowProducts, setIsShowProducts] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState();
  const [uniqueProducts, setUniqueProducts] = useState([]);
  const [filteredAdsData, setFilteredAdsData] = useState<FilteredAdsData[]>();

  async function getBrands() {
    try {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      const res = await getAllAds(token);
      setData(res);
    } catch (error) {
      alert("Error downloading data!");
      console.log("Error fetching data!", error);
    }
  }

  const showProducts = (title) => {
    const filteredProducts = data?.ads.filter((ad) => ad.brand_name === title);
    const uniqueProducts = Array.from(
      new Set<Products>(
        filteredProducts.map((product: any) => product.product_name)
      )
    );

    setUniqueProducts(uniqueProducts);
    setSelectedBrand(title);
    setIsShowBrands(false);
    setIsShowProducts(true);
  };

  const filterProductAds = (selectedProduct) => {
    const filterAdsForProducts = data.ads.filter(
      (ad) =>
        ad.brand_name === selectedBrand && ad.product_name === selectedProduct
    );
    setFilteredAdsData(filterAdsForProducts);
    setIsShowProducts(false);
  };

  const checkAdsActiveByBrandName = async () => {
    const allBrandsData = data?.ads.filter((ad) => ad.brand_name) || [];

    const brandStatusMap = new Map();

    for (const brand of allBrandsData) {
      const existingBrand = brandStatusMap.get(brand.brand_name);
      if (!existingBrand) {
        brandStatusMap.set(brand.brand_name, {
          brandName: brand.brand_name,
          isActive: brand.active,
        });
      } else if (!existingBrand.isActive && brand.active) {
        existingBrand.isActive = true;
      }
    }

    const brandStatus: BrandStatus = Array.from(brandStatusMap.values());
    const sortedBrandStatus = brandStatus.sort((a, b) =>
      a.brandName.localeCompare(b.brandName)
    );
    setBrandStatus(sortedBrandStatus);
  };

  const activeBrands = brandStatus?.filter((brand) => brand.isActive).length;
  const inactiveBrands = brandStatus?.filter((brand) => !brand.isActive).length;

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    checkAdsActiveByBrandName();
  }, [data]);

  return (
    <>
      {isShowBrands ? (
        <div className={style.layout}>
          <h1>Brands</h1>
          <div className={style.layout}>
            <div>
              <h2>Active</h2>
            </div>
            <div className={style.cardAlignment}>
              {activeBrands > 0 ? (
                brandStatus
                  ?.filter((brand) => brand.isActive)
                  .map((brand, id) => (
                    <Card key={id} className={style.cards}>
                      <Card.Body className={style.cardBody}>
                        <Card.Title>{brand.brandName}</Card.Title>
                        <span
                          data-testid="greenDot"
                          className={style.greenDot}
                        ></span>
                        <button
                          onClick={() => showProducts(brand.brandName)}
                          className={style.cardButton}
                        >
                          Products
                        </button>
                      </Card.Body>
                    </Card>
                  ))
              ) : (
                <Card className={style.cards}>
                  <Card.Body className={style.cardBody}>
                    No active brands!
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>
          <div className={style.layout}>
            <hr className={style.greyLine} />
            <div className={style.inactiveText}>
              <h2>Inactive</h2>
            </div>
            <div className={style.cardAlignment}>
              {inactiveBrands > 0 ? (
                brandStatus
                  ?.filter((brand) => brand.isActive === false)
                  .map((brand, id) => (
                    <Card key={id} className={style.cards}>
                      <Card.Body className={style.inactiveCardBody}>
                        <Card.Title>{brand.brandName}</Card.Title>
                        <span
                          data-testid="redDot"
                          className={style.redDot}
                        ></span>
                        <button
                          onClick={() => showProducts(brand.brandName)}
                          className={style.cardButton}
                        >
                          Products
                        </button>
                      </Card.Body>
                    </Card>
                  ))
              ) : (
                <Card className={style.cards}>
                  <Card.Body className={style.cardBody}>
                    No inactive brands!
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>
        </div>
      ) : isShowProducts ? (
        <AdminProductSelection
          uniqueProducts={uniqueProducts}
          selectedBrand={selectedBrand}
          data={data as Data}
          isShowBrands={isShowBrands}
          onToggle={setIsShowBrands}
          onProductClick={filterProductAds}
          getBrands={getBrands as unknown as () => Promise<Data>}
        />
      ) : (
        <AdminAdsTable
          filterAdsForProducts={filteredAdsData}
          isShowProducts={isShowProducts}
          onToggle={setIsShowProducts}
        />
      )}
    </>
  );
};

export default AdminBrandSelection;
