import AdminBrandSelection from "./brand-product-selection/AdminBrandSelection";
import { useState } from "react";
import CreateAdForm from "../../shared/components/forms/create-ad-form/CreateAdForm";
import { Modal } from "react-bootstrap";

function AdOverview() {
  const [showCreateAds, setShowCreateAds] = useState(false);

  const createAds = () => {
    setShowCreateAds(true);
  };

  return (
    <>
      <Modal
        show={showCreateAds}
        fullscreen
        animation={false}
        onHide={() => setShowCreateAds(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create promotion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateAdForm
            showCreateAds={showCreateAds}
            setShowCreateAds={setShowCreateAds}
          />
        </Modal.Body>
      </Modal>
      <div>
        <button onClick={createAds} className="create-ad-button">
          Create new ad
        </button>
      </div>
      <hr />
      <>
        <AdminBrandSelection />
      </>
    </>
  );
}

export default AdOverview;
