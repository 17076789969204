import { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import FormInputInformation from "../../molecules/form-input-information/FormInputInformation";
import style from "./CreateAdForm.module.scss";
import SaveAndContinueButton from "../../atoms/SaveAndContinueButton/SaveAndContinueButton";
import {
  adminCreateAd,
  getAllLanguages,
  getCountrySearch,
} from "../../../../services/api-service/ad-service";
import { useAuth0 } from "@auth0/auth0-react";
import {
  checkEmptyFields,
  isValidUrl,
} from "../../../../services/util-service";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import TextAreaInput from "../../atoms/TextAreaInput/TextAreaInput";
import HoverInfo from "../../molecules/hover-info/HoverInfo";
import { Toast } from "react-bootstrap";

type Props = {
  setShowCreateAds: (showCreateAds: boolean) => void;
  showCreateAds: boolean;
};

const CreateAdForm = ({ showCreateAds, setShowCreateAds }: Props) => {
  const formElem = useRef(null);
  const [languages, setLanguages] = useState([
    {
      keyCode: "",
      displayValue: "",
    },
  ]);
  const [ad, setAd] = useState({
    advertiser_name: "",
    ingredient_keywords: "",
    brand_name: "",
    product_name: "",
    url: "",
    language: "",
    hover_title: "",
    hover_pic: "",
    hover_pic_portrait: true,
    hover_text: "",
    suggestion_prefix: "",
    suggestion_text: "",
    country_ids: [],
    is_alternative: false,
    inspo_prefix: "",
    inspo_text: "",
    is_inspo: false,
    utensil_prefix: "",
    utensil_text: "",
    is_utensil: false,
    cpm: "",
    is_vegan: false,
  });
  let [adToBeCreated] = useState({
    advertiser_name: "",
    ingredient_keywords: [],
    brand_name: "",
    product_name: "",
    url: "",
    language: "",
    hover_title: "",
    hover_pic: "",
    hover_pic_portrait: true,
    hover_text: "",
    suggestion_prefix: "",
    suggestion_text: "",
    country_ids: [],
    is_alternative: false,
    inspo_prefix: "",
    inspo_text: "",
    is_inspo: false,
    utensil_prefix: "",
    utensil_text: "",
    is_utensil: false,
    cpm: "",
    is_vegan: false,
  });
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleSubmit, setValue } = useForm({});
  const [errMsg, setErrMsg] = useState("");
  const [missingFieldErrMsg, setMissingFieldErrMsg] = useState("");
  const [urlErrMsg, setUrlErrMsg] = useState("");
  const [urlImgErrMsg, setUrlImgErrMsg] = useState("");
  const [showToastMessage, setShowToastMessage] = useState(false);

  const adArr = [
    ad.advertiser_name,
    ad.brand_name,
    ad.ingredient_keywords,
    ad.product_name,
    ad.url,
    ad.language,
    ad.hover_title ? ad.hover_title && ad.hover_pic && ad.hover_text : null,
    ad.is_alternative ? ad.suggestion_prefix : null,
    ad.is_alternative ? ad.suggestion_text : null,
    ad.is_inspo ? ad.inspo_prefix : null,
    ad.is_inspo ? ad.inspo_text : null,
    ad.is_utensil ? ad.utensil_prefix : null,
    ad.is_utensil ? ad.utensil_text : null,
    ad.cpm,
    ad.is_vegan
  ];

  const successMessage = "Promotion(s) created successfully!";
  const [matchingLocations, setMatchingLocations] = useState([
    {
      value: 0,
      label: "",
    },
  ]);
  // the 4 consts below are used for the locations field
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const mandatory = false;

  useEffect(() => {
    setErrMsg(errMsg);
  }, [ad, errMsg]);

  useEffect(() => {
    getLanguages();
  }, []);

  /*   
  we don't use the searchCountry function now, so we don't need this useEffect
  useEffect(() => {
    searchCountry(inputValue);
  }, [inputValue]); */

  useEffect(() => {
    updateAd({ ...ad });
  }, [selectedOptions]);

  /*   async function searchCountry(inputValue: string) {
    try {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      await getCountrySearch(token, inputValue).then((res) => {
        const matchLocs = res.countries.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setMatchingLocations(matchLocs);
      });
    } catch (error: any) {
      setErrMsg("An unexpected error occured.");
    }
  } */

  async function getLanguages() {
    try {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      getAllLanguages(token).then((res) => {
        const langs = res.languages.map((item) => ({
          keyCode: item.iso,
          displayValue: item.name,
        }));
        setLanguages(langs);
      });
    } catch (error: any) {
      setErrMsg("An unexpected error occured.");
    }
  }

  function setLanguage(e) {
    updateAd({ ...ad, language: e });
  }

  function setAdAlternative(e) {
    e.stopPropagation();
    updateAd({ ...ad, is_alternative: e.target.checked });
  }

  function setAdHoverPicPortrait(e) {
    e.stopPropagation();
    updateAd({ ...ad, hover_pic_portrait: e.target.checked });
  }

  function setAdInspo(e) {
    e.stopPropagation();
    updateAd({ ...ad, is_inspo: e.target.checked });
  }

  function setAdUtensil(e) {
    e.stopPropagation();
    updateAd({ ...ad, is_utensil: e.target.checked });
  }

  function setAdVegan(e) {
    e.stopPropagation();
    updateAd({ ...ad, is_vegan: e.target.checked });
  }

  const updateAd = (data) => {
    setAd((prevAd) => ({
      advertiser_name: data.advertiser_name,
      ingredient_keywords: data.ingredient_keywords,
      brand_name: data.brand_name,
      product_name: data.product_name,
      url: data.url,
      language: data.language || prevAd.language,
      hover_title: data.hover_title,
      hover_pic: data.hover_pic,
      hover_pic_portrait: data.hover_pic_portrait,
      hover_text: data.hover_text,
      suggestion_prefix: data.suggestion_prefix,
      suggestion_text: data.suggestion_text,
      inspo_prefix: data.inspo_prefix,
      inspo_text: data.inspo_text,
      utensil_prefix: data.utensil_prefix,
      utensil_text: data.utensil_text,
      country_ids: selectedOptions,
      is_alternative:
        data.is_alternative !== undefined
          ? data.is_alternative
          : prevAd.is_alternative,
      is_inspo: data.is_inspo !== undefined ? data.is_inspo : prevAd.is_inspo,
      is_utensil:
        data.is_utensil !== undefined ? data.is_utensil : prevAd.is_utensil,
      cpm: data.cpm,
      is_vegan: data.is_vegan
    }));
  };

  function _validateForm() {
    setErrMsg("");
    setMissingFieldErrMsg("");
    setUrlErrMsg("");
    setUrlImgErrMsg("");
    if (checkEmptyFields(adArr)) {
      setMissingFieldErrMsg("Please fill in all the required fields.");
      return false;
    } else if (!isValidUrl(ad.url)) {
      setUrlErrMsg("The URL of the ad does not have a valid format.");
      return false;
    } else if (ad.hover_title && !isValidUrl(ad.hover_pic)) {
      setUrlImgErrMsg("The URL of the image does not have a valid format.");
      return false;
    }
    setErrMsg("");
    setMissingFieldErrMsg("");
    setUrlErrMsg("");
    setUrlImgErrMsg("");
    return true;
  }

  async function submitForm() {
    adToBeCreated = {
      ...ad,
      ingredient_keywords: ad.ingredient_keywords?.split(", "),
    };

    if (_validateForm()) {
      const token = await getAccessTokenSilently({ ignoreCache: true });
      try {
        const createdAds = await adminCreateAd(token, adToBeCreated);
        if (createdAds["ads"] && createdAds["ads"][0].advertiser_name) {
          setErrMsg(successMessage);
          setShowToastMessage(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (createdAds.error_backend) {
          setErrMsg(JSON.parse(createdAds.error_backend.http_body).detail);
        }
      } catch (error: any) {
        setErrMsg("An unexpected error occured.");
      }
    }
  }

  // everything below this point and until the return is for the locations field
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (searchInput != "") {
      setDropdownVisible(true);
    } else if (searchInput == "") {
      setDropdownVisible(false);
    }
  }, [searchInput]);

  const handleOptionChange = (event) => {
    if (!selectedOptions.includes(event.target.value)) {
      setSelectedOptions((oldArray) => [...oldArray, +event.target.value]);
    }
    setSearchInput("");
  };

  const filteredOptions = matchingLocations.filter((option) =>
    option.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleRemoveOption = (val) => {
    setSelectedOptions((oldArray) => oldArray.filter((value) => value !== val));
  };

  return (
    <>
      <Toast
        onClose={() => setShowToastMessage(false)}
        show={showToastMessage}
        delay={4000}
        autohide
        animation
        style={{
          position: "fixed",
          top: 80,
          right: 20,
          zIndex: 9999,
          color: "white",
        }}
        bg={errMsg === successMessage ? "success" : "danger"}
      >
        <Toast.Body>
          {errMsg === successMessage ? successMessage : errMsg}
        </Toast.Body>
      </Toast>
      <form
        ref={formElem}
        id="adForm"
        className={style.my_container}
        onChange={handleSubmit(updateAd)}
      >
        <div className={style.subtitles}>
          <h2>Product information</h2>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="advertiser_name"
              id="advertiser_name"
              placeholder="Enter advertiser's name"
              title="Advertiser Name"
              mandatory={true}
              setValue={setValue}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
          </div>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="brand_name"
              id="brand_name"
              placeholder="Enter the brand's name"
              title="Brand Name"
              mandatory={true}
              setValue={setValue}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="product_name"
              id="product_name"
              placeholder="Enter the name of the product"
              title="Product Name"
              mandatory={true}
              setValue={setValue}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
          </div>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="ingredient_keywords"
              id="ingredient_keywords"
              placeholder="Enter the ingredients, eg: ui, uien, grote uien, middel uien"
              title="Ingredients"
              mandatory={true}
              hoverText="Input the ingredients linked to this ad, comma separated."
              setValue={setValue}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="url"
              id="url"
              placeholder="Enter the brand's url"
              title="Brand's URL"
              mandatory={true}
              setValue={setValue}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
            {urlErrMsg && <p className={style.error_msg}>{urlErrMsg}</p>}
          </div>
          <div className={style.form_input_info}>
            <Dropdown
              name="languages"
              id="languages"
              placeholder="Choose the language of your ad"
              title="Ad language"
              mandatory={true}
              setValue={setValue}
              options={languages}
              handleClick={(e) => setLanguage(e)}
            />
            {missingFieldErrMsg && (
              <p className={style.error_msg}>{missingFieldErrMsg}</p>
            )}
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <FormInputInformation
              name="cpm"
              id="cpm"
              placeholder="Enter the CPM"
              title="CPM"
              mandatory={true}
              setValue={setValue}
            />
          </div>
        </div>
        <div>
          <div className={style.subtitles}>
            <h2>Hoverbox information</h2>
          </div>
          <div className={style.my_row}>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="hover_title"
                id="hover_title"
                placeholder="Enter the title of the ad"
                title="Ad title"
                mandatory={false}
                setValue={setValue}
              />
            </div>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="hover_pic"
                id="hover_pic"
                placeholder="Enter the url of the ad's image"
                title="Image URL"
                mandatory={ad.hover_title ? true : false}
                setValue={setValue}
              />
              {urlImgErrMsg && (
                <p className={style.error_msg}>{urlImgErrMsg}</p>
              )}
            </div>
          </div>
        </div>
        <div className={style.my_row}>
          <TextAreaInput
            name="hover_text"
            id="hover_text"
            placeholder="Enter the description of the ad"
            title="Description"
            maxLength={110}
            mandatory={ad.hover_title ? true : false}
            hoverText="Input the description of the ad."
            setValue={setValue}
          />
          {missingFieldErrMsg && (
            <p className={style.error_msg}>{missingFieldErrMsg}</p>
          )}
        </div>

        {/* location field was set to display none as we currently do not use it */}
        <div style={{ display: "none" }} className={style.my_row}>
          <div className={style.form_input_info}>
            <div>
              <p className={style.title}>
                {`Locations ${mandatory ? "*" : ""}`}
              </p>
              <div className={style.input_container}>
                <input
                  className={style.input}
                  type="text"
                  value={searchInput}
                  onChange={handleSearchInput}
                  placeholder="Choose one or more locations"
                />
                {dropdownVisible && (
                  <select
                    multiple={true}
                    className={style.dropdown}
                    size={Math.min(filteredOptions.length, 6)}
                  >
                    {filteredOptions.map((option, index) => {
                      return (
                        !selectedOptions.includes(option.value) && (
                          <option
                            value={option.value}
                            key={index}
                            onClick={handleOptionChange}
                            className={style.option}
                          >
                            {option.label}
                          </option>
                        )
                      );
                    })}
                  </select>
                )}
              </div>
              {true && (
                <div className={style.hover_info}>
                  <HoverInfo text="Input the locations where the ad will be displayed." />
                </div>
              )}
              <div>
                {matchingLocations.map((o) => {
                  return (
                    selectedOptions.includes(o.value) && (
                      <div className={style.tag}>
                        <button
                          className={style.tag_button}
                          onClick={() => handleRemoveOption(o.value)}
                        >
                          x
                        </button>
                        {o.label}
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={style.subtitles}>
          <h2>Additional information</h2>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Hover Pic Portrait</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={ad.hover_pic_portrait}
                  onChange={(e) => setAdHoverPicPortrait(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Alternative Suggestion</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={ad.is_alternative}
                  disabled={ad.is_inspo || ad.is_utensil}
                  onChange={(e) => setAdAlternative(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>

        {ad.is_alternative && (
          <div className={style.my_row}>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="suggestion_prefix"
                id="suggestion_prefix"
                placeholder="Enter the prefix for the Alternative Promotion"
                title="Alternative Promotion prefix"
                mandatory={true}
                setValue={setValue}
              />
              {missingFieldErrMsg && (
                <p className={style.error_msg}>{missingFieldErrMsg}</p>
              )}
            </div>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="suggestion_text"
                id="suggestion_text"
                placeholder="Enter the text for the Alternative Promotion"
                title="Alternative Promotion text"
                mandatory={true}
                setValue={setValue}
              />
              {missingFieldErrMsg && (
                <p className={style.error_msg}>{missingFieldErrMsg}</p>
              )}
            </div>
          </div>
        )}

        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Inspirational Tip</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={ad.is_inspo}
                  disabled={ad.is_alternative || ad.is_utensil}
                  onChange={(e) => setAdInspo(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>

        {ad.is_inspo && (
          <div className={style.my_row}>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="inspo_prefix"
                id="inspo_prefix"
                placeholder="Enter the prefix for the Inspirational Tip"
                title="Inspirational Tip prefix"
                mandatory={true}
                setValue={setValue}
              />
              {missingFieldErrMsg && (
                <p className={style.error_msg}>{missingFieldErrMsg}</p>
              )}
            </div>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="inspo_text"
                id="inspo_text"
                placeholder="Enter the text for the Inspirational Tip"
                title="Inspirational Tip text"
                mandatory={true}
                setValue={setValue}
              />
              {missingFieldErrMsg && (
                <p className={style.error_msg}>{missingFieldErrMsg}</p>
              )}
            </div>
          </div>
        )}
        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Utensil promotion</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={ad.is_utensil}
                  disabled={ad.is_alternative || ad.is_inspo}
                  onChange={(e) => setAdUtensil(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>

        {ad.is_utensil && (
          <div className={style.my_row}>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="utensil_prefix"
                id="utensil_prefix"
                placeholder="Enter the prefix for the utensil promotion"
                title="Utensil promotion prefix"
                mandatory={true}
                setValue={setValue}
              />
              {missingFieldErrMsg && (
                <p className={style.error_msg}>{missingFieldErrMsg}</p>
              )}
            </div>
            <div className={style.form_input_info}>
              <FormInputInformation
                name="utensil_text"
                id="utensil_text"
                placeholder="Enter the text for the utensil promotion"
                title="Utensil promotion text"
                mandatory={true}
                setValue={setValue}
              />
              {missingFieldErrMsg && (
                <p className={style.error_msg}>{missingFieldErrMsg}</p>
              )}
            </div>
          </div>
        )}

        <div className={style.my_row}>
          <div className={style.form_input_info}>
            <label>
              <span>Vegan</span>
              <label className={style.toggle}>
                <input
                  className={style.toggle}
                  type="checkbox"
                  checked={ad.is_vegan}
                  onChange={(e) => setAdVegan(e)}
                />
                <span className={`${style.slider} ${style.round}`}></span>
              </label>
            </label>
          </div>
        </div>

        <div className={style.to_right}>
          <p className={style.error_msg} style={{ color: "red" }}>
            {errMsg !== successMessage && errMsg}
            {missingFieldErrMsg}
            {urlErrMsg}
            {urlImgErrMsg}
          </p>
          <SaveAndContinueButton
            name="saveButton"
            handleClick={() => submitForm()}
          ></SaveAndContinueButton>

          <button
            onClick={() => setShowCreateAds(!showCreateAds)}
            className={style.cancelButton}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateAdForm;
