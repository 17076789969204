import { Link } from "react-router-dom";
import LogoutButton from "../../../../components/LogoutButton";
import { UserType } from "../../../../models/user";
import logo from "../../../../assets/logo-v2.svg";

function NavBar({ userType }) {
  const AdvNav = (
    <nav>
      <Link className="nav-bar-item" to="/advertiser/">
        Dashboard
      </Link>
      <Link className="nav-bar-item" to="/advertiser/campaigns">
        Campaigns
      </Link>
      <Link className="nav-bar-item" to="/advertiser/payment">
        Payment Info
      </Link>
      <Link className="nav-bar-item" to="/advertiser/profile">
        Profile
      </Link>
    </nav>
  );

  const PubNav = (
    <nav>
      <Link className="nav-bar-item" to="/publisher/">
        Dashboard
      </Link>
      <Link className="nav-bar-item" to="/publisher/code">
        Code
      </Link>
      <Link className="nav-bar-item" to="/publisher/payment">
        Payment Info
      </Link>
      <Link className="nav-bar-item" to="/publisher/profile">
        Profile
      </Link>
    </nav>
  );

  const AdminNav = (
    <nav>
      <Link className="nav-bar-item" to="/admin/ads">
        Ads
      </Link><Link className="nav-bar-item" to="/admin/publishers">
        Publishers
      </Link>
    </nav>
  );

  return (
    <div className="header">
      <div className="nav-bar">
        <img src={logo} alt="" />
        {userType == UserType.Advertiser && AdvNav}
        {userType == UserType.Publisher && PubNav}
        {userType == UserType.Admin && AdminNav}
        <LogoutButton />
      </div>
    </div>
  );
}

export default NavBar;