import { FormInputProps } from "../../../models/FormInputProps";
import { InputProps } from "../../../models/InputProps";
import TextInput, { TextInputProps } from "../../atoms/TextInput/TextInput";
import HoverInfo from "../hover-info/HoverInfo";
import style from "./FormInputInformation.module.scss";

function FormInputInformation(props: FormInputInformationProps) {
  const type = props.type || TextInputType.TEXT;

  const redStar = <span className={style.redStar}>*</span>;

  return (
    <>
      <div className={style.titleBox}>
        <p className={style.title}>
          {`${props.title}`} {props.mandatory && redStar}
        </p>

        {props.hoverText && (
          <div className={style.hover_info}>
            <HoverInfo text={props.hoverText} />
          </div>
        )}
      </div>
      <TextInput {...props} type={type} />
    </>
  );
}

export default FormInputInformation;

export interface FormInputInformationProps extends FormInputProps, InputProps {
  type?: TextInputType;
}

export enum TextInputType {
  TEXT = "text",
  PASSWORD = "password"
}
